<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>Услуги по жизненным<br/> и бизнес-ситуациям
            <CznUserInfo :change-callback="behalUserChangeCallback"></CznUserInfo>


            <div class="header-buttons">

              <div class="menu-button user-menu-button">
                <router-link to="/situations/situationChoose">
                  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9.18C9.6 1.84 10.7 1 12 1C13.3 1 14.4 1.84 14.82 3H19ZM12 3C11.7348 3 11.4804 3.10536 11.2929 3.29289C11.1054 3.48043 11 3.73478 11 4C11 4.26522 11.1054 4.51957 11.2929 4.70711C11.4804 4.89464 11.7348 5 12 5C12.2652 5 12.5196 4.89464 12.7071 4.70711C12.8946 4.51957 13 4.26522 13 4C13 3.73478 12.8946 3.48043 12.7071 3.29289C12.5196 3.10536 12.2652 3 12 3ZM7 7V5H5V19H19V5H17V7H7ZM12 9C12.5304 9 13.0391 9.21071 13.4142 9.58579C13.7893 9.96086 14 10.4696 14 11C14 11.5304 13.7893 12.0391 13.4142 12.4142C13.0391 12.7893 12.5304 13 12 13C11.4696 13 10.9609 12.7893 10.5858 12.4142C10.2107 12.0391 10 11.5304 10 11C10 10.4696 10.2107 9.96086 10.5858 9.58579C10.9609 9.21071 11.4696 9 12 9ZM8 17V16C8 14.9 9.79 14 12 14C14.21 14 16 14.9 16 16V17H8Z"
                        fill="#0033A0"/>
                  </svg>
                </router-link>
              </div>


<!--              <div class="menu-button download-menu-button">-->
<!--                <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path-->
<!--                      d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"-->
<!--                      fill="#0033A0"/>-->
<!--                </svg>-->
<!--              </div>-->


              <div :class="{ active: isActive }" class="menu-button search-menu-button" @click="isActive = !isActive">
                <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z"
                      fill="#0033A0"/>
                </svg>
              </div>

              <div class="search-panel-wrap">
                <div class="search-panel">
                  <div class="wrap">

                    <a class="close" @click="isActive = false">
                      <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                            fill="#0033A0"/>
                      </svg>
                    </a>

                    <h1>Поиск</h1>

                    <v-row>

                      <v-col cols="12" lg="3">
                        <v-text-field label="№ карточки" v-model="querySpec.cznSystemNumber"></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3">
                        <v-text-field label="Название ЖС/БС" v-model="querySpec.situationName"></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="3">
                        <v-menu
                            min-width="auto"
                            offset-y
                            transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="querySpec.requestDate | dateFormat"
                                v-bind="attrs"
                                v-on="on"
                                append-icon="mdi-calendar"
                                label="Дата запроса"
                                readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="querySpec.requestDate"
                              no-title
                              scrollable
                              locale="RU"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" lg="3">
                        <v-select label="Статус"
                                  v-model="querySpec.status"
                                  :items="situationStatuses"
                                  item-text="name"
                                  item-value="code"
                        ></v-select>
                      </v-col>

                    </v-row>

                    <div class="button-wrap">
                      <button class="btn btn-primary" @click="search()">применить</button>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </h1>


          <table class="situations-table">
            <thead>
            <tr>
              <th>№ карточки</th>
              <th>ЖС/БС</th>
              <th>Дата запроса</th>
              <th>Статус</th>
              <th>Специалист</th>
              <th>Добавлено</th>
              <th>Изменено</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="situationValue in situationValues" :key="'trv'+situationValue.id">
              <td>{{situationValue.cznSystemNumber}}</td>
              <td>{{situationValue.situationInDict.situationName}}</td>
              <td>{{situationValue.requestDate | dateFormat}}</td>
              <td>{{situationValue.status === 'IN_PROGRESS' ? 'В процессе' : 'Завершено'}}</td>
              <td>{{situationValue.specialistFullName}}</td>
              <td>{{situationValue.openingDate | dateFormat}}</td>
              <td>{{situationValue.changedDate | dateFormat}}</td>
              <td>
                <div class="edit">
                  <router-link :to="'/situations/situationInfo/exi/'+situationValue.id">
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M18.13 12L19.39 10.74C19.83 10.3 20.39 10.06 21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.89 21 5 21H11V19.13L11.13 19H5V5H12V12H18.13ZM14 4.5L19.5 10H14V4.5ZM19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83ZM22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19Z"
                          fill="#0033A0"/>
                    </svg>
                  </router-link>
                </div>
                <a class="delete" @click.prevent="deleteSituationValue(situationValue.id)">
                  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                        fill="#0033A0"/>
                  </svg>
                </a>
              </td>
            </tr>
            </tbody>
          </table>


        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

import CznUserInfo from "./elements/CznUserInfo";

export default {
  name: "SituationsRegister",
  props: {},
  components: {CznUserInfo},
  data() {
    return {
      situationStatuses: [
        {code: "IN_PROGRESS", name: "В процессе"},
        {code: "FINISHED", name: "Завершено"}
      ],
      querySpec: {
        userName: this.$behalfUser,
        cznSystemNumber: null,
        situationName: null,
        requestDate: null,
        status: null
      },
      situationValues: [],
      date: null,
      menu: false,
      isActive: false,
      apiLoaded: false,
      errorText: null
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    async deleteSituationValue(situationId){
      if(!confirm("Вы действительно хотите удалить запись реестра?"))
        return
      let req = await this.$postFormDataApi("/situations/deleteSituationValue", {situationId: situationId})
      if (req.ok) {
        await this.loadValues()
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        this.submitButtonDisabled = false
      }
    },

    async behalUserChangeCallback() {
      this.querySpec.userName = this.$behalfUser
      await this.loadValues()
    },

    async loadValues(){
      let req = await this.$getApi("/situations/getSituationValues",
          this.querySpec)
      if (req.ok) {
        this.situationValues = req.payload
        // console.log(this.situationValues)
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    async search(){
      await this.loadValues()
      this.isActive = false
    },

    async initialize(){
      await this.loadValues()
    }
  },
  async beforeMount() {
    await this.initialize()
    this.apiLoaded = true
  }
};

</script>
